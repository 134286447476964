import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  token: "",
  login_with_email: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLoginWithEmail: (state, action) => {
      state.login_with_email = action.payload;
    },
    reset: (state) => {
      state.user = {};
      state.token = "";
      state.login_with_email = false;
    },
  },
});

export const { setToken, setUser, setLoginWithEmail, reset } =
  userSlice.actions;

export default userSlice.reducer;
