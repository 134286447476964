import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import BidsModal from "./modal/bidsModal";
import BuyModal from "./modal/buyModal";
import { useRouter } from "next/router";
import Header01 from "./header/Header01";
import { useEffect } from "react";

export default function Layout({ children }) {
      useEffect(() => {
    const links = Array.from(document.querySelectorAll("a")).filter((a) => {
      const text = a.textContent.toLowerCase();
      return text === "login" || text === "register";
    });

    links.forEach((link) => link.remove());
  }, []);
 
  const route = useRouter();
  // header start
  let header = <Header01 />;


  return (
    <>
      {header}
      <Wallet_modal />
      <BidsModal />
      <BuyModal />
      <main>{children}</main>
      <Footer />
    </>
  );
}
