import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import web3Reducer from "./web3Slice";
import userReducer from "./userSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    web3: web3Reducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
