import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import { GlobalContextProvider } from "../components/GlobalContext";
import { QueryClient, QueryClientProvider } from "react-query";
import InitAccount from "components/InitAccount";

if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}

function MyApp({ Component, pageProps }) {
  const queryClient = new QueryClient();
  const router = useRouter();
  const pid = router.asPath;

  return (
    <>
      <Meta title="Home 1" />

      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <QueryClientProvider client={queryClient}>
            <MetaMaskProvider>
              <GlobalContextProvider>
                {pid.includes("/login") ||
                pid.includes("/password/reset") ||
                pid === "/forgot-password" ? (
                  <Component {...pageProps} />
                ) : (
                  <InitAccount>
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                  </InitAccount>
                )}
              </GlobalContextProvider>
            </MetaMaskProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default MyApp;
