import axios from "plugins/axios";
export async function login(body) {
  return await axios.post("/api/api/auth/login", body);
}
export async function registerToBackend(body) {
  return await axios.post("/api/api/auth/register", body);
}
export async function forgotPassword(email) {
  return await axios.post("/api/forgot-password", email);
}
export async function resetPassword({ token, email, password }) {
  return await axios.post("/api/api/auth/password/reset/" + token, {
    email,
    password,
  });
}
export async function csrf() {
  return await axios.get("/api/sanctum/csrf-cookie");
}
