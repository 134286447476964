const footerMenuList = [
  // {
  //   id: 1,
  //   title: "Marketplace",
  //   diffClass: "md:col-start-7",
  //   list: [
  //     {
  //       id: 1,
  //       href: "#",
  //       text: "All NFTs",
  //     },
  //     {
  //       id: 2,
  //       href: "#",
  //       text: "art",
  //     },
  //     {
  //       id: 3,
  //       href: "#",
  //       text: "music",
  //     },
  //     {
  //       id: 4,
  //       href: "#",
  //       text: "domain names",
  //     },
  //     {
  //       id: 5,
  //       href: "#",
  //       text: "collections",
  //     },
  //     {
  //       id: 6,
  //       href: "#",
  //       text: "virtual world",
  //     },
  //   ],
  // },
  {
    id: 2,
    title: "Company",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/projects",
        text: "Projects",
      },
      {
        id: 2,
        href: "/team",
        text: "Team",
      },
      {
        id: 3,
        href: "/applyNow",
        text: "Apply Now",
      },
      {
        id: 4,
        href: "/whitelist",
        text: "Whitelist",
      },
      {
        id: 5,
        href: "/contact",
        text: "contact",
      },
      // {
      //   id: 4,
      //   href: "#",
      //   text: "Our Blog",
      // },
      // {
      //   id: 5,
      //   href: "#",
      //   text: "FAQ",
      // },
    ],
  },
  // {
  //   id: 3,
  //   title: "My Account",
  //   diffClass: "",
  //   list: [
  //     {
  //       id: 1,
  //       href: "#",
  //       text: "Authors",
  //     },
  //     {
  //       id: 2,
  //       href: "#",
  //       text: "Collection",
  //     },
  //     {
  //       id: 3,
  //       href: "#",
  //       text: "Author Profile",
  //     },
  //     {
  //       id: 4,
  //       href: "#",
  //       text: "Create Item",
  //     },
  //   ],
  // },
];

const socialIcons = [
  {
    id: 1,
    href: "https://t.me/NextUnicorn4",
    text: "telegram",
  },
  {
    id: 2,
    href: "https://instagram.com/4nextunicorn",
    text: "instagram",
  },
  {
    id: 3,
    href: "https://www.youtube.com/channel/UCcZw0bu5i1J0M0BB3E7_l9A",
    text: "youtube",
  },
  {
    id: 4,
    href: "https://twitter.com/4nextunicorn",
    text: "twitter",
  },
];

export { footerMenuList, socialIcons };
