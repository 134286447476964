import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import { changePassword } from "services/api/user";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { GlobalContext } from "./GlobalContext";

export default function AskForPasswordModal({ address, onSuccess = () => {} }) {
  const { loginUser, askForPasswordModal } = useContext(GlobalContext);
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [customError, setCustomError] = useState(null);

  const formSchema = z.object({
    password: z.string().nonempty(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
  });
  const handleLogin = async ({ password }) => {
    setLoading(true);
    await loginUser({ wallet_address: address, password });
    setLoading(false);
    reset();
  };
  return (
    <div
      className={askForPasswordModal ? "modal fade show block" : "modal fade"}
    >
      4
      <div className="modal-dialog w-[calc(100%-10px)] sm:w-3/4 lg:w-1/4">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="placeBidLabel">
              Login
            </h5>
          </div>
          {/* <!-- Body --> */}
          <form onSubmit={handleSubmit(handleLogin)} className="modal-body p-6">
            <span className="normal-case">
              Enter your password to login your account
            </span>
            <div className="mt-6 mb-4">
              <label className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
                Password
              </label>
              <input
                type="password"
                className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white px-3"
                placeholder="Enter password"
                {...register("password")}
              />
              {errors.password && (
                <div className="text-red text-sm">
                  {errors.password.message}
                </div>
              )}
            </div>
            {customError && (
              <div className="rounded-md p-2 bg-red/25 text-red mb-4">
                {customError}
              </div>
            )}
            <button
              disabled={loading}
              className="bg-accent flex space-x-2 shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
            >
              {loading && (
                <img src="/spinner-white.svg" className="w-6 h-6" alt="" />
              )}
              <span>Login</span>
            </button>
          </form>
          {/* <!-- end body --> */}
        </div>
      </div>
    </div>
  );
}
