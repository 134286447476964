import axios from "plugins/axios.js";

export function me() {
  return axios.get("/api/api/profile/me");
}
export function transactions() {
  return axios.get("/api/api/transactions", {
    params: {
      sort: {
        created_at: "desc",
      },
    },
  });
}
export function updateWalletAddress(body) {
  return axios.put("/api/api/profile/editWallets", body);
}
export function changeEmail(body) {
  return axios.put("/api/api/profile/changeEmail", body);
}
export function changePassword(body) {
  return axios.put("/api/api/profile/change-password", body);
}
export function verifyEmailCode({ id, code }) {
  return axios.put("/api/api/profile/verify", {
    two_factor_type: 2,
    owner_type: "App\\Models\\User",
    owner_id: id,
    type: 2,
    code,
  });
}
export function resendVerifyEmailCode({ id }) {
  return axios.put("/api/api/profile/resend", {
    owner_type: "App\\Models\\User",
    owner_id: id,
    type: 2,
  });
}
