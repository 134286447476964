import axios from "plugins/axios.js";
const getParamForProjects = (val) => {
  return (
    {
      2: { last_projects: true },
      3: { status: 40 },
      4: { status: 60 },
    }[val] ?? null
  );
};
export async function projects({ queryKey }) {
  const params = getParamForProjects(queryKey[1]);
  return await axios.get("/api/projects", {
    params: { ...params },
  });
}
export async function project({ queryKey }) {
  return await axios.get("/api/projects/" + queryKey[1], {
    headers: {
      userId: queryKey[2],
    },
  });
}

export function vote({ id, body }) {
  return axios.post("/api/api/projects/" + id + "/votes", body);
}

export function resolveVote({ projectId, voteId }) {
  return axios.put("/api/api/projects/" + projectId + "/votes/" + voteId);
}

export function invest({ id, body }) {
  return axios.post("/api/api/projects/" + id + "/stakes", body);
}

export function resolveInvest({ projectId, stakeId }) {
  return axios.put("/api/api/projects/" + projectId + "/stakes/" + stakeId);
}
