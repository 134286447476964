import { createContext, useRef, useState } from "react";
import { reset, setToken, setUser, setLoginWithEmail } from "redux/userSlice";
import Cookies from "js-cookie";
import { csrf, login } from "services/api/auth";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { useRouter } from "next/router";
import { me } from "services/api/user";
import {
  setGovernanceAllowance,
  setStakeAllowance,
  setNxtuBalance,
} from "redux/web3Slice";
import { getAllowance, getNXTUBalance } from "services/blockchain";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [loginError, setLoginError] = useState({
    show: false,
    message: "",
  });
  const [askForPasswordModal, setAskForPasswordModal] = useState(false);
  const restrictedRoutesForAuthUser = ["/login"];
  const router = useRouter();
  const scrollRef = useRef({
    scrollPos: 0,
  });
  const dispatch = useDispatch();

  const handlePersist = (data) => {
    dispatch(reset());
    dispatch(setUser(data.user));
    dispatch(setToken(data.access_token));
    Cookies.set("4next_token", data.access_token);
    if (restrictedRoutesForAuthUser.includes(router.pathname)) {
      router.push("/");
    }
  };
  const { mutateAsync: loginUser, isLoading: isLoginLoading } = useMutation(
    login,
    {
      onSuccess: (data) => {
        if (data.data.status) {
          if (askForPasswordModal) setAskForPasswordModal(false);
          handlePersist(data.data.data);
        } else if (data.data.data.status_code === 1031)
          setAskForPasswordModal(true);
        else {
          setLoginError({
            show: true,
            message:
              data.data.message ||
              data.data.data.message ||
              "An error occured, please try refreshing your page.",
          });
        }
      },
    }
  );
  const { mutateAsync: getMe, isLoading: isMeLoading } = useMutation(me, {
    onSuccess: (data) => {
      if (data.data.status) {
        handlePersist({
          user: data.data.data.user,
          access_token: Cookies.get("4next_token"),
        });
      }
    },
  });
  const { mutateAsync: mutateGovernanceAllowance } = useMutation(
    (account) => getAllowance(account, "governance"),
    {
      onSuccess: (data) => {
        dispatch(setGovernanceAllowance(data));
      },
    }
  );
  const { mutateAsync: mutateStakeAllowance } = useMutation(
    (account) => getAllowance(account, "stake"),
    {
      onSuccess: (data) => {
        dispatch(setStakeAllowance(data));
      },
    }
  );
  const { mutateAsync: mutateNXTUBalance } = useMutation(
    (account) => getNXTUBalance(account),
    {
      onSuccess: (data) => {
        dispatch(setNxtuBalance(data));
      },
    }
  );
  const { data } = useQuery("csrf", csrf);
  const context = {
    askForPasswordModal,
    setAskForPasswordModal,
    scrollRef,
    loginUser,
    isLoginLoading,
    getMe,
    isMeLoading,
    handlePersist,
    mutateGovernanceAllowance,
    mutateStakeAllowance,
    mutateNXTUBalance,
    loginError,
    setLoginError,
  };

  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
};
