import { useMutation } from "react-query";
import { useMetaMask } from "metamask-react";
import { useContext, useEffect } from "react";
import { getAmountToApply, getMinAmountForVote } from "services/blockchain";
import {
  setAddress,
  reset as resetWeb3,
  setBurnAmountForApply,
  setMinAmountForVote,
} from "redux/web3Slice";
import { reset as resetUser } from "redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { GlobalContext } from "./GlobalContext";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import AskForPasswordModal from "./AskForPasswordModal";
import { updateWalletAddress } from "services/api/user";

const restrictedRoutesForUnauthUser = ["/profile", "/admin"];
export default function InitAccount({ children }) {
  const {
    loginError,
    setLoginError,
    loginUser,
    getMe,
    mutateGovernanceAllowance,
    mutateStakeAllowance,
    mutateNXTUBalance,
    askForPasswordModal,
    setAskForPasswordModal,
  } = useContext(GlobalContext);
  const router = useRouter();
  const { login_with_email, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { account, status } = useMetaMask();
  const { mutate: mutateGovernanceAmountToApply } = useMutation(
    getAmountToApply,
    {
      onSuccess: (data) => {
        data = data / 10 ** 8;
        dispatch(setBurnAmountForApply(data));
      },
    }
  );
  const { mutate: mutateGovernanceMinAmountForVote } = useMutation(
    getMinAmountForVote,
    {
      onSuccess: (data) => {
        data = data / 10 ** 8;
        dispatch(setMinAmountForVote(data));
      },
    }
  );
  const { mutate: mutateUpdateWallet } = useMutation(updateWalletAddress, {
    onSuccess: (data) => {
      console.log("data :>> ", data);
      if (data.data.status) {
        getMe();
      } else {
        setLoginError({
          show: true,
          message:
            data.data.message ||
            data.data.data.message ||
            "An error occured, please try refreshing your page.",
        });
        dispatch(resetUser());
        loginUser({ wallet_address: account });
      }
    },
  });

  useEffect(() => {
    if (status === "connected") {
      mutateGovernanceAmountToApply();
      mutateGovernanceMinAmountForVote();
      mutateGovernanceAllowance(account);
      mutateStakeAllowance(account);
      mutateNXTUBalance(account);
      dispatch(setAddress(account));
      if (user.id) {
        mutateUpdateWallet({
          ...(user.wallet_address &&
            user.wallet_address.length > 0 && {
              address_to_remove: user.wallet_address[0],
            }),
          address_to_add: account,
        });
      } else loginUser({ wallet_address: account });
    } else if (status === "notConnected") {
      dispatch(resetWeb3());
      Cookies.remove("token");
      if (!login_with_email) {
        dispatch(resetUser());
      }
      if (restrictedRoutesForUnauthUser.includes(router.pathname)) {
        router.push("/");
      }
    } else {
      if (Cookies.get("4next_token")) getMe();
    }
    if (askForPasswordModal) setAskForPasswordModal(false);
  }, [account]);
  return (
    <div>
      {children}
      <AskForPasswordModal address={account} />
      <div className={loginError.show ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog w-[calc(100%-10px)] sm:w-3/4 lg:w-1/4">
          <div className="modal-content">
            <div className="modal-header justify-center">
              <h5 className="modal-title text-center">Something went wrong</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setLoginError({
                    show: false,
                    message: "",
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>
            {/* <!-- Body --> */}
            <div className="modal-body p-6 flex flex-col gap-4 items-center justify-center">
              <span>{loginError.message}</span>
            </div>
            {/* <!-- end body --> */}
          </div>
        </div>
      </div>
    </div>
  );
}
