import axios from "axios";
import Cookies from "js-cookie";
const instance = axios.create({
  // baseURL: "http://localhost:3000/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance.interceptors.request.use((config) => {
  const token = Cookies.get("4next_token");
  const csrf = Cookies.get("XSRF-TOKEN");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  if (csrf) {
    config.headers["X-XSRF-TOKEN"] = csrf;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove("4next_token");
      window.location.href = "/login";
    }
    return error.response;
  }
);

export default instance;
