import { ethers } from "ethers";
import address from "artifacts/contractAddresses.json";
import abi from "artifacts/contractAbis.json";

let ethereum;

if (typeof window !== "undefined") {
  ethereum = window.ethereum;
}

// const toWei = (num) => ethers.utils.parseEther(num.toString());
// const fromWei = (num) => ethers.utils.formatEther(num);

const csrEthereumContract = async (c) => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(address[c], abi[c], signer);
  return contract;
};

const ssrEthereumContract = async (c) => {
  const provider = new ethers.providers.JsonRpcProvider(
    "https://solemn-young-bridge.bsc.quiknode.pro/e069b724a822c8387965abcf158e8b5699fe02bc"
  );
  const wallet = ethers.Wallet.createRandom();
  const signer = provider.getSigner(wallet.address);
  const contract = new ethers.Contract(address[c], abi[c], signer);
  return contract;
};

const connectWallet = async () => {
  try {
    if (!ethereum) return reportError("Please install Metamask");
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    store.dispatch(setWallet(accounts[0]));
  } catch (error) {
    reportError(error);
  }
};

const monitorWalletConnection = async () => {
  try {
    if (!ethereum) return reportError("Please install Metamask");
    const accounts = await ethereum.request({ method: "eth_accounts" });

    window.ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });

    window.ethereum.on("accountsChanged", async () => {
      store.dispatch(setWallet(accounts[0]));
      await monitorWalletConnection();
    });

    if (accounts.length) {
      store.dispatch(setWallet(accounts[0]));
    } else {
      store.dispatch(setWallet(""));
      reportError("Please, connect wallet, no accounts found.");
    }
  } catch (error) {
    reportError(error);
  }
};

const getProject = async () => {
  const contract = await ssrEthereumContract("governance");
  const project = await contract.projects(1);
  return project;
};
const getAmountToApply = async () => {
  const contract = await ssrEthereumContract("governance");
  const amountToApply = await contract.amountToApply();
  return amountToApply;
};
const getMinAmountForVote = async () => {
  const contract = await ssrEthereumContract("governance");
  const minAmountToVote = await contract.minAmountToVote();
  return minAmountToVote;
};

const getNXTUBalance = async (walletAddress) => {
  try {
    if (!ethereum) return reportError("Please install Metamask");
    const contract = await csrEthereumContract("token");
    const balance = await contract.balanceOf(walletAddress);
    return ethers.utils.formatUnits(balance, 8);
  } catch (error) {
    reportError(error);
  }
};

const getAllowance = async (walletAddress, contractAddress) => {
  try {
    if (!ethereum) return reportError("Please install Metamask");
    const contract = await csrEthereumContract("token");
    const allowance = await contract.allowance(
      walletAddress,
      address[contractAddress]
    );
    return parseFloat(ethers.utils.formatUnits(allowance, 8));
  } catch (error) {
    reportError(error);
  }
};

const voteProject = async (payload) => {
  try {
    if (!ethereum) return reportError("Please install Metamask");
    const contract = await csrEthereumContract("governance");
    return contract.voteProject(payload.projectId, payload.voteFor);
  } catch (error) {
    reportError(error);
  }
};
const stakeProject = async (payload) => {
  try {
    if (!ethereum) return reportError("Please install Metamask");
    const contract = await csrEthereumContract("stake");
    return contract.stake(
      payload.projectId,
      payload.amount * 10 ** 8,
      "0x7D5e8cEd625bE17a668F166a9d146FbDB51C27ac"
    );
  } catch (error) {
    reportError(error);
  }
};

const applyProject = async () => {
  try {
    if (!ethereum) return reportError("Please install Metamask");
    const contract = await csrEthereumContract("governance");
    return contract.applyProject();
  } catch (error) {
    reportError(error);
  }
};
const approveProject = async ({
  id,
  apr,
  locked_time,
  vote_end,
  stake_end,
  ref_percentage,
  amount_to_collect,
}) => {
  try {
    if (!ethereum) return reportError("Please install Metamask");
    const contract = await csrEthereumContract("governance");
    return contract.approveProject(
      id,
      apr,
      locked_time,
      vote_end,
      stake_end,
      ref_percentage,
      amount_to_collect
    );
  } catch (error) {
    reportError(error);
  }
};

const approveGovernance = async (amount) => {
  try {
    if (!ethereum) return reportError("Please install Metamask");
    const contract = await csrEthereumContract("token");
    return contract.approve(address["governance"], amount);
  } catch (error) {
    reportError(error);
  }
};
const approveStake = async (amount) => {
  try {
    if (!ethereum) return reportError("Please install Metamask");
    const contract = await csrEthereumContract("token");
    return contract.approve(address["stake"], amount);
  } catch (error) {
    reportError(error);
  }
};

const reportError = (error) => {
  console.log(error.message);
};

export {
  monitorWalletConnection,
  getProject,
  getAllowance,
  getAmountToApply,
  getMinAmountForVote,
  voteProject,
  stakeProject,
  applyProject,
  approveProject,
  approveGovernance,
  approveStake,
  connectWallet,
  getNXTUBalance,
};
