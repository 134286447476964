import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WalletButton from "components/wallet-btn/WalletButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useMutation } from "react-query";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import moment from "moment";
import { approveStake, stakeProject } from "services/blockchain";
import { invest, resolveInvest } from "services/api/project";
import { GlobalContext } from "components/GlobalContext";
import { setStakeAllowance } from "redux/web3Slice";
import { ethers } from "ethers";
import Link from "next/link";

const formSchema = z.object({
  amount: z
    .string()
    .nonempty()
    .refine((val) => parseInt(val) > 0, {
      message: "Must be greater than 0",
    }),
});

const BidsModal = ({ project, isShow, hide, onComplete }) => {
  const router = useRouter();
  const pid = router.query.item;

  const dispatch = useDispatch();
  const { mutateStakeAllowance } = useContext(GlobalContext);
  const [isStakeLoading, setIsStakeLoading] = useState(false);
  const [customError, setCustomError] = useState(null);
  const { stakeAllowance, address, nxtuBalance } = useSelector(
    (state) => state.web3
  );
  const { user } = useSelector((state) => state.user);
  const { mutateAsync: resolveInvestToBackend } = useMutation(resolveInvest);
  const { mutateAsync: investToBackend } = useMutation(
    async (data) => await invest({ id: pid, body: data })
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
  });
  const handleInvest = async (data) => {
    const amount = parseInt(data.amount);
    if (!Cookies.get("4next_token") || !user.id || address.length < 1)
      return setCustomError("You need to connect your wallet");
    if (!user.is_password_set)
      return setCustomError(
        <span className="normal-case">
          You need to set a password from{" "}
          <Link href="/profile">
            <span className="cursor-pointer font-bold underline">profile</span>
          </Link>
        </span>
      );
    if (nxtuBalance < amount)
      return setCustomError("You need to have enough NXTU balance");
    setIsStakeLoading(true);
    setCustomError(null);
    try {
      if (stakeAllowance <= amount) {
        const tx = await approveStake((amount + 1) * 10 ** 8);
        const approveReceipt = await tx.wait();
        let newAllowance = approveReceipt.events.find(
          (e) => e.event === "Approval"
        ).args.value;
        newAllowance = parseFloat(ethers.utils.formatUnits(newAllowance, 8));
        dispatch(setStakeAllowance(newAllowance));
        if (newAllowance <= amount)
          throw new Error("Approving failed, please try again.");
      }
      const endTimestamp = moment().unix() + project.locked_time;
      const res = await investToBackend({
        started_at: moment(),
        ends_at: moment.unix(endTimestamp),
        amount,
      });
      if (res.status !== 200) throw res.data;
      const tx = await stakeProject({
        projectId: project.project_id,
        amount,
      });
      await tx.wait();
      await mutateStakeAllowance(address);
      const response = await resolveInvestToBackend({
        projectId: pid,
        stakeId: res.data.data.stake.id,
      });
      if (response.status !== 200) throw response.data;
      hide();
      reset();
      onComplete();
    } catch (error) {
      if (error?.message) setCustomError(error.message);
    } finally {
      setIsStakeLoading(false);
    }
  };
  return (
    <div>
      <div className={isShow ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog w-full max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Invest
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => !isStakeLoading && hide()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              {!address ? (
                <div className="space-y-6 ">
                  <span>Connect your wallet to continue</span>
                  <WalletButton />
                </div>
              ) : (
                <form onSubmit={handleSubmit(handleInvest)}>
                  <div>
                    <label
                      htmlFor="amount"
                      className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white"
                    >
                      Invest Amount<span className="text-red">*</span>
                    </label>
                    <input
                      type="number"
                      id="amount"
                      className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white px-3"
                      placeholder="Enter amount"
                      {...register("amount")}
                    />
                    {errors.amount && (
                      <div className="text-red text-sm">
                        {errors.amount.message}
                      </div>
                    )}
                  </div>
                  {customError && (
                    <div className="rounded-md mt-4 p-2 bg-red/25 text-red mb-4">
                      {customError}
                    </div>
                  )}
                  <button
                    className="group bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all flex items-center space-x-2 justify-center w-full mt-4"
                    disabled={isStakeLoading}
                    type="submit"
                  >
                    {isStakeLoading && (
                      <img
                        src="/spinner-white.svg"
                        className="w-6 h-6"
                        alt=""
                      />
                    )}
                    <span>Invest</span>
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidsModal;
