import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "4 Next Unicorn",
  keyword:
    "Cryptocurrency, Blockchain, Bitcoin, Ethereum, Altcoin, ICO, Token, Decentralized, Smart Contracts, Mining, Digital Currency, Wallet, Exchange, Trading, Investment, Start-up, Entrepreneurship, Venture Capital, Angel Investor, Business Model, Pitch, Innovation, Disruption, Scalability, Market Analysis, Customer Acquisition, Revenue Model, Growth Hacking, Product-Market Fit, Pivot, Risk Management, Financial Planning, Marketing Strategy, Branding, Competitive Advantage, Target Audience, Market Research, Customer Segmentation, Value Proposition, User Experience, Agile Methodology, Lean Startup, Minimum Viable Product, Fundraising, Exit Strategy, Intellectual Property, Patents, Copyright, Trademark, Legal Compliance",
  desc: "4 Next Unicorn Creates Resources In The Fields Of Software, Sales, Marketing, And Finance Within Its Ecosystem, In Collaboration With The Next Generation Of Investors And Entrepreneurs, To Uncover The Next Unicorn Company.",
};

export default Meta;
