import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  governanceAllowance: 0,
  stakeAllowance: 0,
  nxtuBalance: 0,
  address: "",
  contract: {
    burnAmountForApply: 0,
    minAmountForVote: 0,
  },
};

export const web3Slice = createSlice({
  name: "web3",
  initialState,
  reducers: {
    setGovernanceAllowance: (state, action) => {
      state.governanceAllowance = action.payload;
    },
    setStakeAllowance: (state, action) => {
      state.stakeAllowance = action.payload;
    },
    setNxtuBalance: (state, action) => {
      state.nxtuBalance = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setBurnAmountForApply: (state, action) => {
      state.contract.burnAmountForApply = action.payload;
    },
    setMinAmountForVote: (state, action) => {
      state.contract.minAmountForVote = action.payload;
    },
    reset: (state) => {
      state.governanceAllowance = 0;
      state.stakeAllowance = 0;
      state.address = "";
    },
  },
});

export const {
  setGovernanceAllowance,
  setStakeAllowance,
  setNxtuBalance,
  setAddress,
  setBurnAmountForApply,
  setMinAmountForVote,
  reset,
} = web3Slice.actions;

export default web3Slice.reducer;
